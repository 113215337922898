import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import FanCard from './FanCard'
import { gridSizes, processGatsbyImageDataMock } from '../common/gatsbyImageData'

const FanCardGrid = () => {
  const {
    data: { nodes },
  } = useStaticQuery(graphql`
    query FanClubQuery {
      data: allStrapiTestimonial(sort: { fields: createdAt, order: DESC }) {
        nodes {
          name
          text
          image {
            gatsbyImageDataMock
          }
        }
      }
    }
  `)
  nodes.forEach(node => {
    processGatsbyImageDataMock(node.image, gridSizes)
  })
  return (
    <div className=" mx-auto grid justify-center md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      {nodes.map((fanCard, index) => (
        <FanCard name={fanCard.name} text={fanCard.text} image={fanCard.image} key={index} />
      ))}
    </div>
  )
}

export default FanCardGrid
