import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import StillHaveQuestions from '../components/StillHaveQuestions'
import FanCardGrid from '../components/FanCardGrid'

const FanClubPage = () => {
  return (
    <Layout className={'container mx-auto'} breadcrumbs={false}>
      <Seo
        description="With over 45 years of outstanding, top-rated customer service, it is no surprise so many people love shopping at Gray and Sons. "
        title="Gray and Sons Jewelers | Fan Club "
      />
      <h1 className="underline-title my-5 text-center text-[calc(1.2rem+1vw)] uppercase">
        The <nobr>Gray & Sons </nobr> Fan Club
      </h1>
      <FanCardGrid />
      <StillHaveQuestions />
    </Layout>
  )
}

export default FanClubPage
