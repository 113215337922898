import * as React from 'react'
import { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const FanCard = ({ name, text, image }) => {
  const [readMore, setReadMore] = useState(false)
  return (
    <article className="relative m-10 flex h-[510px] w-[350px] items-center rounded-lg border border-gray-300 p-6 shadow-md duration-300 hover:shadow-lg mx-auto">
      <div className={'h-full w-full '}>
        <div
          className={
            (readMore
              ? ' left-[8.5rem] -top-10 w-[5rem] rounded-[100px] '
              : ' top-3 left-3 w-[20.25rem] rounded-[6px] ') +
            ' absolute  aspect-square overflow-hidden duration-300'
          }
        >
          <GatsbyImage alt={name} image={getImage(image.gatsbyImageData)} />
        </div>
        <h2
          className={
            (readMore ? ' pt-[1.5rem] ' : ' pt-[20.5rem] ') +
            ' w-full py-1 text-center font-bold duration-300 '
          }
        >
          {name}
        </h2>
        <p
          className={
            'absolute bottom-7 left-5 w-[310px] pr-2 text-left duration-200 ease-in ' +
            (readMore ? 'h-[80%] overflow-auto' : 'h-[20%] overflow-hidden line-clamp-4')
          }
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>

      <button
        onClick={() => setReadMore(!readMore)}
        className="absolute -bottom-5 left-[calc(50%-80px)] h-10 w-40 rounded-full border-2 border-gray-300 bg-white text-center hover:bg-black hover:text-white"
      >
        {readMore ? '  Read Less  ' : '  Read More '}
      </button>
    </article>
  )
}
FanCard.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default FanCard
